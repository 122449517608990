import React, { useState, useContext, useEffect } from "react";
// make a new context
export const MyContext = React.createContext();
export const useMyContext = () => useContext(MyContext);

// create the provider
export const MyProvider = (props) => {
    const [menuOpenState, setMenuOpenState] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState("Broccoli")
    const [selectedPose, setSelectedPose] = useState(1);
    const [selectedRtePart, setSelectedRtePart] = useState(1);
    return (
        <MyContext.Provider
            value={{
                selectedRtePart, setSelectedRtePart,
                isMenuOpen: menuOpenState,
                selectedProduct, setSelectedProduct,
                selectedPose, setSelectedPose,
                toggleMenu: () => setMenuOpenState(!menuOpenState),
                stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen),
            }}
        >

            {props.children}
        </MyContext.Provider>
    );
};
