//Import React Library
import React, { Component, Suspense, lazy } from "react";

//import react-router
import { Switch, Route } from "react-router-dom";

//import loading animation
import CircleLoader from "react-spinners/CircleLoader";
import { css } from "@emotion/core";

//import DocVisualizer from "../Layouts/DocumentVisualizer/DocVisualizer";

//import layouts
const Home = lazy(() => import("../Components/Layouts/Home/Home"));
const AboutUs = lazy(() => import("../Components/Layouts/AboutUs/AboutUs"));
const FarmTour = lazy(() => import("../Components/Layouts/FarmTour/FarmTour"));
const Contact = lazy(() => import("../Components/Layouts/Contact/Contact"));
const Products = lazy(() => import("../Components/Layouts/Products/Products"));
const SocialResponsibility = lazy(() => import("../Components/Layouts/SocialResponsibility/SocialResponsibility"));
const Gallery = lazy(() => import("../Components/Reusable/Gallery"));
const Quality = lazy(() => import("../Components/Layouts/Quality/Quality"));
const CompetitiveAdvantages = lazy(() => import("../Components/Layouts/CompetitiveAdvantages/CompetitiveAdvantages"));



const override = css`
    display: block;
    margin: auto auto;
`;

export default class Content extends Component {
    render() {        
        return (
            <Suspense
                fallback={
                    <div className="col w-100 h-100 text-center">
                        <CircleLoader
                            css={override}
                            size={15}
                            color={"var(--primaryText)"}
                            loading={true}
                        />
                        <p className="text-center mt-4">Loading</p>
                    </div>
                }
            >
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route
                        path="/about-us"
                        component={AboutUs}
                    />
                    <Route
                        path="/farm-tour"
                        component={FarmTour}
                    />
                    <Route
                        path="/contact-us"
                        component={Contact}
                    />
                    <Route
                        path="/products"
                        component={Products}
                    />
                    <Route
                        path="/sustainability"
                        component={SocialResponsibility}
                    />
                    <Route
                        path="/gallery"
                        component={Gallery}
                    />
                    <Route
                        path="/quality"
                        component={Quality}
                    />
                    <Route
                        path="/competitive-advantages"
                        component={CompetitiveAdvantages}
                    />
                    <Route component={Home} />
                </Switch>
            </Suspense>
        );
    }
}
