import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MyProvider } from "./Context/context.js";
import { BrowserRouter as Router } from "react-router-dom";
//import 'bootstrap/dist/css/bootstrap.css';
//import 'bootstrap/dist/css/bootstrap.min.css';
/*import '@fortawesome/fontawesome-free/css/all.min.css'; 
import'bootstrap-css-only/css/bootstrap.min.css'; 
import'mdbreact/dist/css/mdb.css';*/
import "primereact/resources/themes/saga-blue/theme.css"
import 'primereact/resources/primereact.min.css'
import "primeicons/primeicons.css"
ReactDOM.render(
  
    <MyProvider>
      <Router>
        <App />
      </Router>
    </MyProvider>

 ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
