import './styles/App.scss';
import './styles/typography.scss';
import Routes from "./Router/Router"


import Copyright from "./Components/MainContainer/Copyright/Copyright"

import {
  isMobile
} from "react-device-detect";
function App() {
  return (
    <div className="App">

      <Routes />
      <div className="div-filler"> 

      </div>
      <Copyright />

    </div>
  );
}

export default App;
